<template>
  <section id="quendrox-ha">
    <img
      src="@/assets/images/quendrox-smart-home.jpg"
      alt="Quendrox HA"
      class="center"
    />
    <div class="readFriendlyLayout">
      <h2>Quendrox HA</h2>
      <p>
        Home automation is an extension of building automation. It is automation
        of the home, housework, industry or activity. Home automation may
        include centralized control of lighting, HVAC (heating, ventilation and
        conditioning), appliances, security locks of gates and doors and other
        systems, to provide improved convenience, comfort, energy efficiency and
        security. Home automation can provide increased quality of life for
        persons who might otherwise require caregivers or institutional care.
      </p>
      <p>
        The popularity of home automation has been increasing greatly in recent
        years due to much higher affordability and simplicity through smartphone
        and tablet connectivity. The concept of the "Internet of Things" has
        tied in closely with the popularization of home automation.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "QuendroxHa"
};
</script>

<style scoped lang="scss">
img {
  border-radius: 10px;
}
</style>
